import React from 'react';
import { connect } from 'react-redux'
import { updateBoard, switchPlayer, setLevel, setWinner, setErrorMessage, setPlayOrder, setOpenPlayOrderDialog} from '../../actions/index';
import Slot from "../Slot/Slot";
import Column from "../Column/Column";
import "./GameBoard.css"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const WIN = 5120;
const LOSS = -5120;

class ConnectedGameBoard extends React.Component {
	constructor(props) {
		super(props);
	}

	handleCloseDialog = (playOrder) => {
	    this.props.setPlayOrder(playOrder);
	    this.props.setOpenPlayOrderDialog(false);
	    if (playOrder === 2) {
			var board = Array(7).fill().map(() => Array(6).fill(0));
			this.botMove(board, 2);
			this.props.updateBoard(board);
		}
	  };

	handleUpdateBoard = (col) => {
		if (this.props.winner !== 0) return;
		var board = [];
		var columnFull = false;
		var row = -1;
		for (var i = 0; i < 7; i++) {
			var column = []
			for (var j = 0; j < 6; j++) {
				if (i !== col) {
					column.push(this.props.board[i][j])
				} else {
					if (this.props.board[col][j] === 0) {
						column.push(this.props.currentPlayer);
						row = j;
						for (var k = j + 1; k < 6; k++) {
							column.push(0);
						}
						break;
					} else {
						column.push(this.props.board[i][j])
					}
					if (j === 5) {
						columnFull = true;
					}
				}
			}
			board.push(column);
		}

		if (columnFull) {
			const errorMessage = "Column " + (col + 1) + " is already full. Please select another column."
			this.props.setErrorMessage(errorMessage);
		} else {
			this.props.setErrorMessage("");
			// console.log(this.staticEval(board));

			this.props.updateBoard(board);
			// const nextPlayer = this.props.currentPlayer === 1? 2: 1;
			// this.props.switchPlayer(nextPlayer);
			if (this.checkWinCondition(board, col)) {
				this.props.setWinner(this.props.currentPlayer);
			} else if (this.checkTieCondition(board)) {
				this.props.setWinner(3);

			} else {
				this.botMove(board, 2);
				// console.log("BOT: " + this.staticEval(board));

			}

		}


	}

	checkWinCondition = (board, col) => {
		var row = -1;
		for (var i = 5; i >= 0; i--) {
			if (board[col][i] !== 0) {
				row = i;
				break;
			}
		}
		var value = board[col][row];

		var downRow = row + 1;
		var upRow = row - 1;
		var sumRow = 1;
		while (downRow < 6 && board[col][downRow] === value) {
			downRow++;
			sumRow++;
		}

		while (upRow >= 0 && board[col][upRow] === value) {
			upRow--;
			sumRow++;
		}

		if (sumRow >= 4)
			return true;

		var leftCol = col - 1;
		var rightCol = col + 1;
		var sumCol = 1;
		while (leftCol >= 0 && board[leftCol][row] === value) {
			leftCol--;
			sumCol++;
		}

		while (rightCol < 7 && board[rightCol][row] === value) {
			rightCol++;
			sumCol++;
		}

		if (sumCol >= 4)
			return true;

		leftCol = col - 1;
		downRow = row + 1;
		var sumPosDiagonal = 1;
		while (leftCol >= 0 && downRow < 6 && board[leftCol][downRow] === value) {
			leftCol--;
			downRow++;
			sumPosDiagonal++;
		}

		rightCol = col + 1;
		upRow = row - 1;
		while (rightCol < 7 && upRow >= 0 && board[rightCol][upRow] === value) {
			rightCol++;
			upRow--;
			sumPosDiagonal++;
		}
		if (sumPosDiagonal >= 4)
			return true;

		// Negative Diagonal
		leftCol = col - 1;
		upRow = row - 1;
		var sumNegDiagonal = 1;
		while (leftCol >= 0 && upRow >= 0 && board[leftCol][upRow] === value) {
			leftCol--;
			upRow--;
			sumNegDiagonal++;
		}

		rightCol = col + 1;
		downRow = row + 1;
		while (rightCol < 7 && downRow < 6 && board[rightCol][downRow] === value) {
			rightCol++;
			downRow++;
			sumNegDiagonal++;
		}
		if (sumNegDiagonal >= 4)
			return true;

		return false;
	}

	restartGame = () => {
		var r = window.confirm("Do you want to start a new game?");
		if (r === true) {
			var board = [[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0]];
			this.props.setErrorMessage("");
			this.props.setWinner(0);
			this.props.updateBoard(board);
			this.props.setOpenPlayOrderDialog(true);
		}
	}

	/**
	 * Evaluate the score of a length 4 segment based on number of tokens.
	 */
	evalSegment = (segment) => {
		var XCount = 0;
		var OCount = 0;
		// count the number of each type of token in segment
		for (var i = 0; i < segment.length; i++) {
			const value = segment[i];
			if (value === 2)
				XCount++;
			else if (value === 1)
				OCount++;
		}

		if (XCount > 0 && OCount > 0)
			// segments with both type of token don't affect score
			return 0; 
		if (XCount === 4)
			return WIN;
		if (OCount === 4)
			return LOSS;

		if (XCount === 0) {
			if (OCount === 3)
				return -500;
			else if (OCount === 2)
				return -100;
			else if (OCount === 1)
				return -10;
			else
				return 0;
		}

		else {
			if (XCount === 3)
				return 500;
			else if (XCount === 2)
				return 100;
			else
				return 10;
		}
	}

	/**
	 * Return a static evaluation score of the current board state
	 */
	staticEval = (board) => {
		var value = 0;

		// evaluate columns
		for (var i = 0; i < 7; i++) {
			for (var j = 0; j < 3; j++) {
				var evalSegment = this.evalSegment(
						[board[i][j], board[i][j + 1], board[i][j + 2], board[i][j + 3]]);
				if (evalSegment === WIN || evalSegment === LOSS) {
					return evalSegment;
				} else {
					value += evalSegment;
				}
			}
		}

		// evaluate rows
		for (i = 0; i < 6; i++) {
			for (j = 0; j < 4; j++) {
				evalSegment = this.evalSegment(
						[board[j][i], board[j + 1][i], board[j + 2][i], board[j + 3][i]]);
				if (evalSegment === WIN || evalSegment === LOSS) {
					return evalSegment;
				} else {
					value += evalSegment;
				}
			}
		}
		
		// save all diagonals as lists
		var diagMap = {};
		for (i = 0; i < 6; i++) {
			for (j = 0; j < 7; j++) {
				var sum = i + j;
				if (!(sum in diagMap)) diagMap[sum] =  [];
				diagMap[sum].push(board[j][i]);
			}
		}
		
		for (i = 0; i < 6; i++) {
			for (j = 0; j < 7; j++) {
				var diff = i - j + 30;
				if (!(diff in diagMap)) diagMap[diff] = [];
				diagMap[diff].push(board[j][i]);
			}
		}
		
		// evaluate diagonals
		for (var key in diagMap) {
			var diag = diagMap[key];
			if (diag.length > 3) {
				for (i = 0; i < diag.length-3; i++){
					var evalSegment = this.evalSegment([diag[i], diag[i+1], diag[i+2], diag[i+3]]);
					if (evalSegment == WIN || evalSegment == LOSS) {
						return evalSegment;
					} else {
						value += evalSegment;
					}
				}
			}
		}
		
		return value;
	}

	/**
	 * Retrieve all columns which are not full.
	 * @return ArrayList<Integer> ArrayList of valid columns.
	 */
	getValidCols = (board) => {
		var columns = [];
		for (var i = 0; i < 7; i++) {
			if (board[i][5] == 0) {
				columns.push(i);
			}
		}
		return columns;
	}


	/**
	 * Implementation of the miniMax algorithm for min player
	 * @param depth specifies how many turns to look into the future
	 */
	minMax = (board, currentPlayer, depth, alpha, beta) => {
		var val = this.staticEval(board);
		if (val === WIN || val === LOSS || depth === 0)
			return [val, -1];

		// get all valid moves
		var columns = this.getValidCols(board);

		if (columns.length == 0)
			return [val, -1];
		
		val = 100000;
		var move = -1;

		// for each valid move, recursively call maxMin
		for (var i = 0; i < columns.length; i++) {
			var col = columns[i];
			this.placeToken(board, currentPlayer, col);
			// switch the active player for the next move
			currentPlayer = 2;
			var value = this.maxMin(board, currentPlayer, depth - 1, alpha, beta)[0];
			if (value < val) {
				move = col;
				val = value;
			}
			// undo the move
			this.removeToken(board, col);
			currentPlayer = 1;
			if (val < beta) {
				beta = val;
			}
			if (beta <= alpha) {
				break;
			}
		}

		return [ val, move ];
	}

	/**
	 * Implementation of the miniMax algorithm for max player
	 * @param depth specifies how many turns to look into the future
	 */
	maxMin = (board, currentPlayer, depth, alpha, beta) => {
		var val = this.staticEval(board);
		if (val === WIN || val === LOSS || depth === 0)
			return [val, -1];

		var columns = this.getValidCols(board);

		if (columns.length == 0)
			return [val, -1];

		val = -100000;
		var move = -1;

		for (var i = 0; i < columns.length; i++) {
			var col = columns[i];
			this.placeToken(board, currentPlayer, col);
			currentPlayer = 1;
			var value = this.minMax(board, currentPlayer, depth - 1, alpha, beta)[0];
			if (value > val) {
				move = col;
				val = value;
			}
			if (val > alpha) {
				alpha = val;
			}
			this.removeToken(board, col);
			currentPlayer = 2;
			if (beta <= alpha) {
				break;
			}
		}

		return [ val, move ];
	}


	/**
	 * Place a token in a column on the board.
	 * @param col Column to place a token in
	 */
	placeToken = (board, currentPlayer, col) => {
		for (var i = 0; i < 6; i++) {
			if (board[col][i] === 0) {
				board[col][i] = currentPlayer;
				break;
			}
		}
	}

	/**
	 * Remove a token from the board. This is used for recursive backtracking in minMax.
	 * @param col
	 */
	removeToken = (board, col) => {
		for (var i = 5; i >= 0; i--) {
			if (board[col][i] !== 0) {
				board[col][i] = 0;
				break;
			}
		}
	}

	/**
	 * Connecto-bot move logic
	 */
	 botMove = (board, currentPlayer) => {
		var col = -1;
		var res = [-1, -1];
		var botLevel = this.props.level;
		var alpha = -1000000;
		var beta = 100000;
		if (botLevel === 1) col = this.getRandomMove(board); // choose moves at random
		else if (botLevel === 2) res = this.maxMin(board, 2, 2, alpha, beta); // run minMax with depth 2
		else if (botLevel === 3) res = this.maxMin(board, 2, 3, alpha, beta); // run minMax with depth 3 
		else if (botLevel === 4) res = this.maxMin(board, 2, 4, alpha, beta); // run minMax with depth 4
		else if (botLevel === 5) res = this.maxMin(board, 2, 7, alpha, beta); // run minMax with depth 5

		
		if (botLevel > 1) {
			col = res[1];
		}
		
		currentPlayer = 2;
		if (col >= 0) {
			this.placeToken(board, currentPlayer, col);
		}

		// check if won
		if (this.checkWinCondition(board, col)) {
			this.props.setWinner(2);
		} else if (this.checkTieCondition(board)) {
			this.props.setWinner(3);
		}
	}

	/**
	 * Check if the board is full.
	 * @return true if the board is full, false otherwise.
	 */
	checkTieCondition = (board) => {
		for (var i = 0; i < 7; i++) {
			if (board[i][5] == 0) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Get a random valid column.
	 * @return
	 */
	getRandomMove = (board) => {
		var columns = this.getValidCols(board);
		var col = columns[Math.floor(Math.random()*columns.length)];
		return col;
	}


	render() {
		const board = this.props.board;

		return (
			<div className="game-container">
				<Dialog
					disableBackdropClick
      				disableEscapeKeyDown
			        open={this.props.openDialog}
			        onClose={this.handleCloseDialog}
			        aria-labelledby="player-order-dialog-title"
			        aria-describedby="player-order-dialog-description"
			        id="player-order-dialog"
			      >
			        <DialogTitle id="player-order-dialog-title">{"Welcome to Connect 4 with Connecto-bot!"}</DialogTitle>
			        <DialogContent>
			          <DialogContentText id="player-order-dialog-description">
			            Choose one option:
			          </DialogContentText>
			        </DialogContent>
			        <DialogActions>
			          <Button onClick={() => this.handleCloseDialog(1)} className="first-player-option">
			            First Player
			          </Button>
			          <Button onClick={() => this.handleCloseDialog(2)} className="second-player-option">
			            Second Player
			          </Button>
			        </DialogActions>
			      </Dialog>
				{
					this.props.winner === 1? 
						<div className="winner-message-1" style={{color: this.props.player1Color}}>
							You won.
						</div> : this.props.winner === 2?
						<div className="winner-message-2">
							Connecto-bot has won.
						</div> : this.props.winner === 3?
						<div className="winner-message-3">
							It's a tie.
						</div> : <span/>

				}
				{
					this.props.errorMessage !== ""? 
					<div className="column-full-message">
						{this.props.errorMessage}
					</div> : <span/>

				}
				
				<div className="game-board">
					{
						board.map((column, colNum) => {
							var columnElements = []
							column.map((value, rowNum) => {
								columnElements.push(<Slot onClick={() => this.handleUpdateBoard(colNum)} value={value} row={rowNum} col={colNum} />);	
							})
							return (<Column content={columnElements} col={colNum} onClick={() => this.handleUpdateBoard(colNum)}/>);
						})
					}
				</div>

				<button className="restart-button" onClick={() => this.restartGame()}>
					Restart Game
				</button>
			</div>
		)
	}
}

const mapStateToProps = state => {
    return {
        board: state.board,
        currentPlayer: state.currentPlayer,
        level: state.botLevel,
        winner: state.winner,
        errorMessage: state.errorMessage,
        player1Color: state.player1Color,
        playOrder: state.playOrder,
        openDialog: state.openPlayOrderDialog
    }
}

const mapDispatchToProps = dispatch => {
	return {
		updateBoard: newBoard => {
			dispatch(updateBoard(newBoard))
		},
		switchPlayer: nextPlayer => {
			dispatch(switchPlayer(nextPlayer))
		},
		setLevel: newLevel => {
			dispatch(setLevel(newLevel))
		},
		setWinner: winner => {
			dispatch(setWinner(winner))
		},
		setErrorMessage: errorMessage => {
			dispatch(setErrorMessage(errorMessage))
		},
		setPlayOrder: playOrder => {
			dispatch(setPlayOrder(playOrder))
		},
		setOpenPlayOrderDialog: openPlayOrderDialog => {
			dispatch(setOpenPlayOrderDialog(openPlayOrderDialog))
		}
	}
}

const GameBoard = connect(mapStateToProps, mapDispatchToProps)(ConnectedGameBoard);

export default GameBoard;