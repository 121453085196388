import React from 'react';
import { connect } from 'react-redux'
import "./Slot.css";


class ConnectedSlot extends React.Component {
	constructor(props) {
		super(props);
	}



	render() {
		switch(this.props.value) {
			case 1:
				return <div className="player1-slot" style={{backgroundColor: this.props.player1Color}}/>;
			case 2:
				return <div className="player2-slot"/>;
			default:
				return <div className="unselected-slot"/>;
		}
	}
}

const mapStateToProps = state => {
    return {
        player1Color: state.player1Color
    }
}

const Slot = connect(mapStateToProps)(ConnectedSlot);

export default Slot;


