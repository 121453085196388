import { UPDATE_BOARD, SWITCH_PLAYER, SET_LEVEL, SET_WINNER, 
            SET_ERROR_MESSAGE, SET_PLAYER_COLOR, SET_PLAY_ORDER,
            SET_OPEN_PLAY_ORDER_DIALOG} from "../constants/action-types";

const initialState = {
	board: [[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0]],
	currentPlayer: 1,
	botLevel: 1,
	winner: 0,
	errorMessage: "",
    player1Color: "#01ffc3",
    playOrder: 0,
    openPlayOrderDialog: true
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BOARD:
            return {...state, board: action.payload};
        case SWITCH_PLAYER:
        	return {...state, currentPlayer: action.payload};
        case SET_LEVEL:
        	return {...state, botLevel: action.payload};
        case SET_WINNER:
        	return {...state, winner: action.payload};
        case SET_ERROR_MESSAGE:
        	return {...state, errorMessage: action.payload};
        case SET_PLAYER_COLOR:
            return {...state, player1Color: action.payload};
        case SET_PLAY_ORDER:
            return {...state, playOrder: action.payload};
        case SET_OPEN_PLAY_ORDER_DIALOG:
            return {...state, openPlayOrderDialog: action.payload};
        default:
            return state;
    }
}

export default rootReducer;