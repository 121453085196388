import { UPDATE_BOARD, SWITCH_PLAYER, SET_LEVEL, SET_WINNER, 
			SET_ERROR_MESSAGE, SET_PLAYER_COLOR, SET_PLAY_ORDER, SET_OPEN_PLAY_ORDER_DIALOG } from "../constants/action-types";

export const updateBoard = newBoard =>
    ({ type: UPDATE_BOARD, payload: newBoard});

export const switchPlayer = nextPlayer =>
    ({ type: SWITCH_PLAYER, payload: nextPlayer});

export const setLevel = newLevel =>
    ({ type: SET_LEVEL, payload: newLevel});

export const setWinner = winner =>
    ({ type: SET_WINNER, payload: winner});

export const setErrorMessage = errorMessage =>
	({ type: SET_ERROR_MESSAGE, payload: errorMessage });

export const setPlayerColor = newColor =>
	({ type: SET_PLAYER_COLOR, payload: newColor });

export const setPlayOrder = playOrder => 
	({ type: SET_PLAY_ORDER, payload: playOrder });

export const setOpenPlayOrderDialog = openPlayOrderDialog =>
	({ type: SET_OPEN_PLAY_ORDER_DIALOG, payload: openPlayOrderDialog })