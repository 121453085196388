import React from 'react';
import './App.css';
import { connect } from 'react-redux'
import { updateBoard, switchPlayer, setLevel, setWinner, setErrorMessage, setPlayerColor, setPlayOrder, setOpenPlayOrderDialog} from './actions/index';
import { styled } from '@material-ui/core/styles';
import GameBoard from "./components/GameBoard/GameBoard";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';



const MySelect = styled(Select)({
  width: '10vw',
  height: '2vw',
  color: '#746D69',
  marginTop: '1vw',
  marginBottom: '1vw',
  position: 'relative',
  fontSize: '1vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const ColorSelect = styled(Select)({
  width: '3.5vw',
  height: '2vw',
  color: '#746D69',
  marginLeft: '0.5vw',
  display: 'flex'
});


class ConnectedApp extends React.Component {
  	constructor(props) {
		super(props);
	}

  handleSelectLevel = (event) => {
  	var r = window.confirm("Do you want to start a new game?");
	if (r === true) {
		this.props.setLevel(event.target.value);
		var board = [[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0, 0]];
		this.props.setErrorMessage("");
		this.props.setWinner(0);
		this.props.updateBoard(board);
		this.props.setOpenPlayOrderDialog(true);
	}
  }

  	handleSelectColor = (event) => {
	  	var r = window.confirm("Do you want to start a new game with this color?");
		if (r === true) {
			var board = [[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0],
			[0, 0, 0, 0, 0, 0]];
			this.props.setErrorMessage("");
			this.props.setWinner(0);
			this.props.updateBoard(board);
			this.props.setPlayerColor(event.target.value);
			this.props.setOpenPlayOrderDialog(true);
	  	}
	}

  render() {
  	return (
		<div className="App">
			 
	    	<div className="title">
	    		Connect 4 with Connecto-bot
	    	</div>
	    	<div className="tutorial">
	    		<div className="tutorial-letters">
	    			Click on any column to start. Your color is 
	    		</div>
	    			    	<FormControl variant="outlined">

	    		<ColorSelect
		          labelId="color-select-label"
		          id="color-select"
		          value={this.props.player1Color}
		          onChange={this.handleSelectColor}
		        >
		          <MenuItem value={"#7fff00"}>
		          	<div id="player-color-1"></div>
		          </MenuItem>
		          <MenuItem value={"#fc6e22"}>
		          	<div id="player-color-2"></div>
		          </MenuItem>
		          <MenuItem value={"#01ffff"}>
		          	<div id="player-color-3"></div>
		          </MenuItem>
		          <MenuItem value={"#01ffc3"}>
		          	<div id="player-color-4"></div>
		          </MenuItem>
		        </ColorSelect>
		        		          </FormControl>

	    	</div>
	    	<FormControl variant="outlined">
		    	<MySelect
		          labelId="level-select-label"
		          id="level-select"
		          value={this.props.level}
		          onChange={this.handleSelectLevel}
		        >
		          <MenuItem value={1}>Beginner</MenuItem>
		          <MenuItem value={2}>Easy</MenuItem>
		          <MenuItem value={3}>Medium</MenuItem>
		          <MenuItem value={4}>Hard</MenuItem>
		          <MenuItem value={5}>Advanced</MenuItem>
		        </MySelect>
		    </FormControl>
	     	<GameBoard />
	     	<div className="contact"> Contact me at quananhchau@gmail.com </div>
	    </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        level: state.botLevel,
        player1Color: state.player1Color
    }
}

const mapDispatchToProps = dispatch => {
	return {
		updateBoard: newBoard => {
			dispatch(updateBoard(newBoard))
		},
		switchPlayer: nextPlayer => {
			dispatch(switchPlayer(nextPlayer))
		},
		setLevel: newLevel => {
			dispatch(setLevel(newLevel))
		},
		setWinner: winner => {
			dispatch(setWinner(winner))
		},
		setErrorMessage: errorMessage => {
			dispatch(setErrorMessage(errorMessage))
		},
		setPlayerColor: newColor => {
			dispatch(setPlayerColor(newColor))
		},
		setOpenPlayOrderDialog: openPlayOrderDialog => {
			dispatch(setOpenPlayOrderDialog(openPlayOrderDialog))
		}
	}
}

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

export default App;

