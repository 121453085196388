import { createStore } from 'redux'

import rootReducer from './reducers/index'

export default function configureStore(preloadedState) {
  const store = createStore(rootReducer)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers/index', () => store.replaceReducer(rootReducer))
  }
  return store
}